import React from 'react'

import Layout from '../components/Layout/Layout'
import '../styles/methodology.scss'
import { Helmet } from 'react-helmet'

export default () => {
  return (
    <Layout title="College Pulse Methodology">
      <main>
        <Helmet>
          <title>College Pulse | Methodology</title>
          <meta property="og:image" content="/cp-website-min.jpg" />
          <script type="text/javascript">{'function initApollo(){var n=Math.random().toString(36).substring(7),o=document.createElement("script"); o.src="https://assets.apollo.io/micro/website-tracker/tracker.iife.js?nocache="+n,o.async=!0,o.defer=!0, o.onload=function(){window.trackingFunctions.onLoad({appId:"66294cedd50c9601c741a4d8"})}, document.head.appendChild(o)}initApollo();'}</script>
        </Helmet>
        <section className="has-background-primary has-padding-6 is-centered methodology-title">
          <h1 className="title has-text-white has-text-weight-bold has-margin-top-4 has-margin-bottom-3">
            OUR METHODOLOGY
          </h1>
        </section>

        <section className="content container has-margin-top-6 has-margin-bottom-6">
          <div className="columns is-multiline is-8 is-variable">
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                What is College Pulse?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                College Pulse is an online survey and analytics company dedicated to understanding
                the attitudes, preferences, and behaviors of today's college students. College Pulse
                offers custom data-driven marketing and research solutions, utilizing its unique
                American College Student Panel that includes over 800,000 undergraduate college
                student respondents from more than 1500 two- and four-year colleges and universities
                in all 50 states.
              </p>
            </div>
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                Why do we focus on college students?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                We believe that today's college students are tomorrow's entrepreneurs, activists,
                tastemakers, and leaders. It's a demographic that is growing, increasingly diverse.
                There are currently roughly 20 million students enrolled in American colleges and
                universities, a 25 percent increase from 2000. That is 20 million “entry point
                consumers” who are still developing their brand preferences. In fact, college
                students are also already making an impact as consumers. Today's college students
                are poised to rack up over $300 billion in discretionary spending. They are immersed
                in digital marketing from an early age and particularly in the field of technology,
                their consumer preferences are now informing their parents spending decisions.
              </p>
            </div>
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How do college students complete interviews?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                If they are selected to participate in a study, an invitation to complete the survey
                is sent directly to their student email address. Panelists also receive a
                notification in the smartphone app alerting them that a survey is ready for them to
                take. Students can complete the survey using the College Pulse App that is available
                on iOS and Android platforms. They can also login directly on the College Pulse
                website to complete the survey on their computer. Given the high penetration of
                smartphones on college campuses &#8212; 97% of students own smartphones<sup>1</sup>{' '}
                &#8212; College Pulse surveys are specifically designed to be taken on this
                platform. We believe that this is the future of survey research.
              </p>
            </div>
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How do we recruit college students to be part of its Undergraduate Student Panel?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                Panel members are recruited by a number of methods to help ensure student diversity
                in the panel population, including web advertising, permission-based email
                campaigns, and partnerships with university-affiliated organizations. To ensure that
                the panel reflects the diverse backgrounds and experiences of the American college
                population we recruit panelists from a wide variety of different institutions. The
                panel includes students attending large public universities, small private colleges,
                historically black colleges like Howard University and religiously-affiliated
                schools such as Brigham Young University and Southern Methodist University.
              </p>
            </div>
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How does College Pulse validate panelists?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                College Pulse uses a two-stage validation process to ensure that all its surveys
                include only students currently enrolled in four-year colleges or universities.
                Students are required to provide an .edu email address to join the panel and
                required to verify that they are currently enrolled either part-time or full-time in
                a four-year degree program. All invitations to complete surveys are sent using the
                student's .edu email address.
              </p>
            </div>
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How does College Pulse ensure its surveys reflect the views of college students
                nationally?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                Our goal is to design high-quality surveys that provide insights about the
                experiences and attitudes of college students. To accomplish this goal, we rebalance
                surveys to ensure that the demographic distribution of the sample matches the
                national population of college students. We apply a post-stratification adjustment
                based on demographic distributions from multiple data sources, including the 2017
                Current Population Survey (CPS), the 2016 National Postsecondary Student Aid Study
                (NPSAS), and the 2021-22 Integrated Postsecondary Education Data System (IPEDS). The
                post-stratification weight rebalances the sample based on a number of important
                benchmark attributes, such as race, gender, class year, voter registration status,
                and financial aid status. The sample weighting is accomplished using an iterative
                proportional fitting (IFP) process that simultaneously balances the distributions of
                all variables. Weights are trimmed to prevent individual interviews from having too
                much influence on the final results.
              </p>
            </div>
            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How does College Pulse ensure response quality?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                College Pulse works hard to ensure that the data we collect accurately represents
                the universe of college students. We use more than 20 demographic categories to
                target the most representative subsamples of our college panel based on the specific
                needs of each client. To ensure top-quality surveys, we subject all client-submitted
                surveys to a question design and methodology review before release. We also
                automatically detect and remove unreliable responders (such as those speeding or
                straightlining) from surveys.
              </p>
            </div>

            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How does College Pulse ensure its panelists confidentiality?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                College Pulse takes our users' security and privacy concerns seriously. We strive to
                ensure that user data is kept secure, and that we collect only as much personal data
                as is required to make our users' experience with College Pulse as efficient and
                satisfying as possible. Panelists' email addresses are never displayed publicly or
                privately in connection with their poll responses. To protect college students from
                having their responses attributed to them personally, identifying information (i.e.,
                demographics) is only shared with fellow panelists if the number of respondents who
                match the selected demographic exceeds five. For more information on how we keep our
                panelists' responses confidential and secure, see our privacy policy.
              </p>
            </div>

            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                I'm a college student! How do I sign-up to complete surveys?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                If you are an undergraduate student currently enrolled in a four-year college or
                university you can download the College Pulse app on your smartphone. You can also
                sign-up on the College Pulse website. Once you sign-up, you have to complete a short
                demographic survey and you can begin sharing your opinion and learning about what
                your campus thinks.
              </p>
            </div>

            <div className="is-half column has-margin-bottom-4">
              <h4 className="has-text-weight-bold has-padding-bottom-0 has-text-primary">
                How can I learn more?
              </h4>
              <hr className="has-background-success has-margin-bottom-3 has-margin-top-3" />
              <p>
                If you have any further questions, please reach out to our Survey Methodology team
                at methodology@collegepulse.com.
              </p>
            </div>
          </div>
        </section>
        <section className="container has-padding-bottom-4">
          <div className="content">
            <small>
              <span>
                <small>1</small> Jeffrey Pomerantz and D. Christopher Brooks, 2017 ECAR Study of
                Undergraduate Students and Information Technology, EDUCAUSE Center for Analysis and
                Research, 2017
              </span>
            </small>
          </div>
        </section>
      </main>
    </Layout>
  )
}
